<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <table width="100%">
                        <tr>
                            <td style="width: 80px;">设备Imei：</td>
                            <td>
                                <el-input v-model="searchItem.imei" size="small" placeholder="请输入设备Imei"></el-input>
                            </td>
                            <td style="width: 80px;">合同编号：</td>
                            <td>
                                <el-input v-model="searchItem.contractNum" size="small" placeholder="请输入合同号"></el-input>
                            </td>
                            <td style="width: 80px;">设备类型：</td>
                            <td>
                                <el-select style="width:100%" size="small" v-model="searchItem.deviceType"
                                           placeholder="请选择公司" @change="changeCompany">
                                    <el-option v-for="item in deviceType" :key="item.value" :label="item.label"
                                               :value="item.value"></el-option>
                                </el-select>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 80px;padding-top: 10px">项目名称：</td>
                            <td style="padding-top: 10px">
                                <el-input v-model="searchItem.projectName" size="small"
                                          placeholder="请输入项目名称"></el-input>
                            </td>
                            <td style="width: 80px;padding-top: 10px">状态：</td>
                            <td style="padding-top: 10px">
                                <el-select style="width:100%" size="small" v-model="searchItem.state"
                                           placeholder="请选择公司" @change="changeCompany">
                                    <el-option v-for="item in deviceState" :key="item.value" :label="item.label"
                                               :value="item.value"></el-option>
                                </el-select>
                            </td>
                            <td style="text-align: center;padding-top: 10px" colspan="2">
                                <el-button type="primary" icon="el-icon-circle-plus" size="small" @click="forwardWindow">
                                    新增转发
                                </el-button>
                                <el-button type="primary" icon="el-icon-search" style="margin-left: 10%" size="small"
                                           @click="searchBtn">搜索
                                </el-button>
                                <el-button type="primary" icon="el-icon-refresh" style="margin-left: 10%" size="small"
                                           @click="reset">重置
                                </el-button>
                            </td>
                        </tr>
                    </table>

                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                v-loading="loading"
                                element-loading-text="拼命加载中"
                                element-loading-spinner="el-icon-loading"
                                element-loading-background="rgba(0, 0, 0, 0.1)"
                                :max-height="tableHeight"
                                style="width: 100%;height: 100%;"
                                :border="true"
                                :row-style="{height:'40px'}"
                                :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}">
                            <el-table-column type="index" width="50" label="排序" align="center"></el-table-column>
                            <el-table-column prop="imei" label="设备Imei" align="center"></el-table-column>
                            <el-table-column prop="contractNum" label="合同号" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="deviceType" label="设备类型" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span>{{scope.row.deviceType==1?'塔机':'升降机'}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="companyName" label="公司名称" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="sceneNo" label="现场编号" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column label="服务周期" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span>{{scope.row.serviceStartDate}} ~ {{scope.row.serviceEndDate}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="状态" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    
                                    <span v-if="scope.row.deviceState == 0" style="color: #98fb98"
                                        >正常</span
                                    >
                                    <span v-if="scope.row.deviceState == 1" style="color: red"
                                        >停报</span
                                    >
                                </template>
                            </el-table-column>
                            <el-table-column prop="id" label="操作" align="center">
                                <template slot-scope="scope">
                                    <div class="opertion">
                                        <i class="iconfont iconzhuanfa" title="转发设置" style="color:#20a0ff" @click="editMaterial(scope.row)"></i>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
                </div>
            </div>
            <el-dialog title="转发设置" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false">
                <div class="dialogdiv">
                    <el-button style="text-align: right;" type="primary" icon="el-icon-circle-plus" size="small"
                               @click="addforward">新增
                    </el-button>
                    <el-table
                            :data="deviceFowerdData"
                            :max-height="tableHeight"
                            style="width: 100%;height: 100%;margin-top:5px"
                            :border="true"
                            :row-style="{height:'40px'}"
                            :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                            :cell-style="{padding:'0',height: lineHeight}">
                        <el-table-column type="index" width="50" label="排序" align="center"></el-table-column>
                        <el-table-column prop="companyName" label="公司名称" align="center"></el-table-column>
                        <el-table-column prop="sign" label="标记" align="center" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="domainName" label="域名" align="center"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column prop="id" label="操作" align="center">
                            <template slot-scope="scope">
                                <div class="opertion">
                                    <i class="iconfont iconshanchu" title="删除" v-if="hasAuthority('material_delete')"
                                       @click="deleteMaterial(scope.row.id)"></i>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-dialog width="30%" title="新增转发" :visible.sync="innerVisible" append-to-body :close-on-click-modal="false">
                        <div class="dialogdiv">
                            <div>
                                <p>公司名称：</p>
                                <el-select style="width:350px" v-model="forwardItem.companyName" placeholder="请选择公司"
                                            filterable  @change="changeCompany">
                                    <el-option ption v-for="(item,index) in forwardConfigData" :key="index"
                                               :label="item.companyName" :value="index"></el-option>
                                </el-select>
                            </div>
                            <div><p>标记：</p>
                                <el-input style="width:350px" :disabled="true" v-model="forwardItem.sign"
                                          placeholder="选择后自动输入"></el-input>
                            </div>
                            <div><p>域名：</p>
                                <el-input style="width:350px" :disabled="true" v-model="forwardItem.domainName"
                                          placeholder="选择后自动输入"></el-input>
                            </div>
                        </div>
                        <span slot="footer" class="dialog-footer">
                        <el-button @click="innerVisible = false">取 消</el-button>
                        <el-button type="primary" @click="addRelationData">确 定</el-button>
                    </span>
                    </el-dialog>
                </div>
                <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
            </el-dialog>

            <!-- 转发配置 -->
            <el-dialog
                    title="提示"
                    :visible.sync="forwardDialog"
                    width="45%"
                    :close-on-click-modal="false"
            >
                <div style="height:40px; width:95%">
                    <span style="width: 80px;float:left;padding-top: 5px;">公司名称：</span>
                    <div style="width:200px;float:left">
                        <el-input v-model="forwardConfigOption.companyName" size="small" placeholder="请输入公司名称"></el-input>
                    </div>
                    <div style=" width: 500px;float:left; margin-bomm">
                        <el-button type="primary" icon="el-icon-search" size="small" class="buttonStyle" @click="searchFowardData">搜索
                        </el-button>
                        <el-button type="primary" icon="el-icon-circle-plus" class="buttonStyle" style="margin-left: 10%" size="small"
                                @click="addForwardConfig">新增
                        </el-button>
                    </div>
                </div>
                <div class="forwardTable">
                    <el-table
                            :data="forwardConfigData"
                            :max-height="tableHeight"
                            style="width: 100%;height: 100%;"
                            :border="true"
                            :cell-style="{padding:'0',height: lineHeight}">
                        <el-table-column type="index" width="50" label="排序" align="center"></el-table-column>
                        <el-table-column prop="companyName" label="公司名称" align="left"></el-table-column>
                        <el-table-column prop="sign" label="标记" align="left" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="domainName" label="域名" align="left"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column prop="id" label="操作" align="center" width="150">
                            <template slot-scope="scope">
                                <div class="opertion">
                                    <span style="color:#20a0ff" @click="editForwardConfig(scope.row)">修改</span>
                                    <span style="color:#20a0ff;margin-left:10px"
                                          @click="delFowardData(scope.row)">删除</span>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="searchItem.pageSize" :pageTotal="forwardConfigTotal"
                                :pageCurrent.sync="searchItem.pageIndex"
                                :pageList="pageList" @handlePageChange="forwardPageChange" @handleSizeChange="forwardPageSizeChange"/>
                </div>
                <el-dialog width="30%" title="新增转发配置" :visible.sync="addForwardDialog" append-to-body :close-on-click-modal="false">
                    <div class="dialogdiv">
                        <div><p>公司名称：</p>
                            <el-input style="width:350px" v-model="addforwardItem.companyName"
                                      placeholder="公司名称"></el-input>
                        </div>
                        <div><p>标记：</p>
                            <el-input style="width:350px" v-model="addforwardItem.sign" placeholder="请输入标记"></el-input>
                        </div>
                        <div><p>域名：</p>
                            <el-input style="width:350px" v-model="addforwardItem.domainName"
                                      placeholder="请输入域名"></el-input>
                        </div>
                    </div>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="addForwardDialog = false">取 消</el-button>
                        <el-button type="primary" @click="clickAddBut">确 定</el-button>
                    </span>
                </el-dialog>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="forwardDialog = false">取 消</el-button>
                    <el-button type="primary" @click="forwardDialog = false">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                pageList: [15, 20, 30, 50],
                tableHeight: '',
                tableData: [],
                totalElements: 0,
                option: {
                    pageSize: 15,
                    pageIndex: 1,
                },
                totalPage: 0,
                lineHeight: '',
                searchItem: {
                    Imei: '',
                    contractNum: '',
                    deviceType: 0,
                    projectName: '',
                    state: -1,
                    pageIndex: 1,
                    pageSize: 15
                },
                depotType: 0,
                dialogVisible: false,
                innerVisible: false,
                forwardDialog: false,
                addForwardDialog: false,
                addoption: {
                    id: '',
                    companyName: '',
                    name: '',
                    phone: '',
                    idcard: ''
                },
                deviceType: [
                    {
                        value: 0,
                        label: "全部"
                    }, {
                        value: 1,
                        label: "塔机"
                    }, {
                        value: 2,
                        label: "升降机"
                    },
                ],
                deviceState: [
                    {
                        value: -1,
                        label: "全部"
                    }, {
                        value: 0,
                        label: "正常"
                    }, {
                        value: 1,
                        label: "停报"
                    }, {
                        value: 2,
                        label: "拆除"
                    }, {
                        value: 3,
                        label: "欠费未停机"
                    },
                ],
                companyList: [],
                forwardItem: {
                    companyName: "",
                    name: "",
                    phone: "",
                },
                addforwardItem: {
                    companyName: "",
                    domainName: "",
                    sign: "",
                },
                forwardConfigOption: {
                    companyName: "",
                    pageIndex: 1,
                    pageSize: 15
                },
                forwardConfigData: [],
                forwardConfigTotal: 0,
                deviceFowerdData: [],
                deviceFowerdOption: {
                    deviceId: "",
                    pageIndex: 1,
                    pageSize: 10000
                },
                deviceFowerdIdSign: '',
                companyFowerdIdSign: '',
                loading: false,
                rowId:'',
            };
        },
        methods: {
            getDictionary() {
                    this.loading = true;
                    this.searchItem.pageIndex = this.option.pageIndex;
                    this.searchItem.pageSize = this.option.pageSize;
                    this.$api.getChargingDevices(this.searchItem).then(res => {
                        if (res.code == 200) {
                            this.tableData = res.data.content;
                            // this.tableData = res.data.useTypeList;
                            this.loading = false;
                        }
                    })
            },
            editMaterial(val) {
                this.dialogVisible = true;
                this.deviceFowerdIdSign = val.id;
                this.rowId = val.id;
                this.queryDeviceFowardData(val.id);
            },
            pageChange(option) {
                this.option.pageIndex = option;
                this.getDictionary();
            },
            pageSizeChange(val) {
                this.option.pageIndex = 1;
                this.option.pageSize = val;
                this.getDictionary();
            },
            forwardPageChange(option) {
                this.forwardConfigOption.pageIndex = option;
                this.searchFowardData();
            },
            forwardPageSizeChange(val) {
                this.forwardConfigOption.pageIndex = 1;
                this.forwardConfigOption.pageSize = val;
                this.searchFowardData();
            },
            searchBtn() {
                this.loading = true;
                this.$api.getChargingDevices(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                        this.loading = false;
                    }
                })
            },
            reset() {
                this.searchItem = {
                    companyName: '',
                    personName: '',
                    phone: '',
                    pageIndex: 1,
                    pageSize: 15
                };
                this.searchBtn();
            },
            addObject() {
                this.dialogVisible = true;
                this.dialogName = "新增调试人员"
                this.addoption.id = '';
                this.addoption.companyName = '';
                this.addoption.name = '';
                this.addoption.phone = '';
                this.addoption.idcard = '';
            },
            addforward() {
                this.forwardConfigOption.companyName = '';
                this.forwardConfigOption.pageIndex = 1;
                this.forwardConfigOption.pageSize = 10000;
                this.searchFowardData();
                this.forwardItem.companyName = '';
                this.forwardItem.sign = '';
                this.forwardItem.domainName = '';
                this.innerVisible = true;
            },
            addForwardConfig() {
                this.addForwardDialog = true;
                this.addforwardItem.id = '';
                this.addforwardItem.companyName = '';
                this.addforwardItem.domainName = '';
                this.addforwardItem.sign = '';
            },
            editForwardConfig(val) {
                this.addForwardDialog = true;
                this.addforwardItem.id = val.id;
                this.addforwardItem.companyName = val.companyName;
                this.addforwardItem.domainName = val.domainName;
                this.addforwardItem.sign = val.sign;
            },
            changeCompany(event) {
                this.companyFowerdIdSign = this.forwardConfigData[event].id;
                this.forwardItem.sign = this.forwardConfigData[event].sign;
                this.forwardItem.domainName = this.forwardConfigData[event].domainName;
            },
            forwardWindow() {
                this.forwardDialog = true;
                this.forwardConfigOption.companyName = '';
                this.forwardConfigOption.pageIndex = 1;
                this.forwardConfigOption.pageSize = 15;
                this.searchFowardData();
            },
            clickAddBut() {
                this.$api.saveForwardCompany(this.addforwardItem).then(res => {
                    if (res.code == 200) {
                            this.$message({
                                type: 'success',
                                message: '添加成功!'
                            });
                        this.addForwardDialog = false;
                        this.forwardConfigOption.companyName = '';
                        this.forwardConfigOption.pageIndex = 1;
                        this.forwardConfigOption.pageSize = 15;
                        this.searchFowardData();
                    } else {
                        alert(res.message);
                        this.addForwardDialog = false;
                    }
                })
            },
            searchFowardData() {
                this.$api.getForwardCompany(this.forwardConfigOption).then(res => {
                    if (res.code == 200) {
                        this.forwardConfigData = res.data.content;
                        this.forwardConfigTotal = res.data.totalElements;
                    }
                })
            },
            delFowardData(val) {
                this.$confirm('此操作将该转发配置, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$api.deleteForwardCompany({id: val.id}).then(res => {
                        if (res.code == 200) {
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            this.searchFowardData();
                        } else {
                            this.$message({
                                type: 'error',
                                message: '删除失败!'
                            });
                        }
                    })
                }).catch(() => {
                });
            },
            queryDeviceFowardData(val) {
                var left = this;
                this.deviceFowerdOption.deviceId = val;
                this.$api.getForward(this.deviceFowerdOption).then(res => {
                    if (res.code == 200) {
                        left.deviceFowerdData = res.data.content;
                        left.forwardConfigTotal = res.data.totalElements;
                    }
                })
            },
            addRelationData() {
                var data = {
                    deviceId: this.deviceFowerdIdSign,
                    forwardId: this.companyFowerdIdSign
                };
                this.$api.saveForward(data).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '添加成功!'
                        });
                        this.queryDeviceFowardData(this.rowId);
                    } else {
                        this.$message({
                            type: 'error',
                            message: '添加失败!'
                        });
                    }
                })
                this.queryDeviceFowardData(this.deviceFowerdIdSign);
                this.innerVisible = false;
            },
            deleteMaterial(val) {
                this.$confirm('此操作将该设备转发配置, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$api.deleteForward({id: val}).then(res => {
                        if (res.code == 200) {
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            this.queryDeviceFowardData(this.deviceFowerdIdSign);
                        } else {
                            this.$message({
                                type: 'error',
                                message: '删除失败!'
                            });

                            this.queryDeviceFowardData(this.deviceFowerdIdSign);
                        }
                    })
                }).catch(() => {
                });
            }

        },
        created() {
            this.tableHeight = window.innerHeight * 0.76;
                this.searchBtn();
                this.tableHeight = (window.innerHeight) * 0.72
        },
    };
</script>
<style lang="scss" scoped>
    .iconfont {
        font-family: "iconfont" !important;
        font-size: 18px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        display: block;
        cursor: pointer;
    }

    .opertion {
        display: flex;
        justify-content: center;
    }

    .iconbianji {
        margin-right: 10px;
        color: #20a0ff;
    }

    .iconshanchu {
        margin-left: 10px;
        color: red;
    }

    .page-body {
        background-color: #FFFFFF;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 100px;
        margin-top: 0.5%;
        background-color: #FFFFFF;
    }

    .handle-box {
        padding: 5px;

    }

    .table-info {
        height: 94%;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
    }

    .table-data {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    td {
        text-align: right;
        white-space: nowrap; /*控制单行显示*/
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*隐藏的字符用省略号表示*/
    }

    .opertion {
        display: flex;
        justify-content: center;
    }

    .dialogdiv {
        div {
            text-align: center;
            margin-top: 10px;
        }

        p {
            width: 80px;
            text-align: left;
            display: inline-block;
        }
    }
    .buttonStyle{
            width: 120px;
            letter-spacing:10px;
            margin-left: 100px;
    }
    .forwardTable{
        float: none;
        margin-top: 5px;
        padding-top: 5px;
        width: 100%;
    }
</style>